<template>
	<div >
		<div class="regist-header">
			<div class="regist-box clearfix">
				<div class="fl">
					<img src="@/assets/images/browser/login_icon_01.png" class="home-icon" />
					<a href="/">系统登录页</a>
				</div>
				<div class="fr header-right-box">
					<a href="/register" class="header-op">用户注册</a>
					<a href="/forgot-password" class="header-op header-style-none">忘记密码？</a>
				</div>
			</div>
		</div>
		<div class="main-box2 browser-tips">
			<div class="check-box">
				<div class="check-content">
					<div>
						<img src="@/assets/images/browser/check_img1.jpg" />
					</div>
					<div class="mt20">
						<img src="@/assets/images/browser/check_img2.jpg" />
					</div>
					<div class="mt20 text-dark" style="height:32px;font-size:18px;">
						推荐您下载使用以下浏览器
					</div>

				</div>
				<div class="check-footer">
					<ul class="check-ul">
						<li class="clearfix">
							<img src="@/assets/images/browser/chrome_icon.jpg" />
							<div>
								<p><a href="https://www.google.cn/chrome/" target="_blank">
										<font color=red><b>Chrome</b></font>
									</a></p>
								<p><a href="https://www.google.cn/chrome/"
										target="_blank">谷歌下载</a>&emsp;
                    <a href="https://browser.360.cn"
										target="_blank">360下载</a>
                  </p>
							</div>
						</li>
						<li class="clearfix">
							<img src="@/assets/images/browser/ie_icon.png" width="52" style="margin-top: -2px;" />
							<div>
								<p><a href="https://www.microsoft.com/zh-cn/edge"
										target="_blank">Edge</a></p>
								<p><a href="https://www.microsoft.com/zh-cn/edge"
										target="_blank">前往下载</a></p>
							</div>

						</li>
						<li class="clearfix">
							<img src="@/assets/images/browser/firefox_icon.jpg" />
							<div>
								<p><a href="http://www.firefox.com.cn/download/" target="_blank">Firefox</a></p>
								<p><a href="http://www.firefox.com.cn/download/" target="_blank">前往下载</a></p>
							</div>
						</li>

						<li class="clearfix">
							<img src="@/assets/images/browser/safair_icon.jpg" />
							<div>
								<p><a href="http://www.apple.com/cn/safari/" target="_blank">Safari</a></p>
								<p><a href="http://www.apple.com/cn/safari/" target="_blank">前往下载</a></p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "home",
		data() {
			return {
			}
		},
		components: {

		},
		methods: {

		},
		mounted() {

		}
	};
</script>

<style>
	@import '~@/assets/css/browser.css';
</style>